<template>
	<div :class="themeClass" class="accontCont width-fill margin-t-10 flex-column bg-fff">
		<el-row>
			<el-col class="font-size18 font-weight700 margin-b-10 titleText">注销账户</el-col>
		</el-row>
		<div class="cancelAccCont width-fill bg-fff">
			<div class="centerBox" v-model="cancelAccList">
				<span class="margin-auto radius30 text-center font-color-FF9900 font-size24 font-weight700">{{cancelAccList.iconText}}</span>
				<div class="font-size18 font-color-333 text-center margin-tb-10">{{cancelAccList.titleText}}</div>
				<p class="font-color-999 margin-tb-10" v-for="(item,index) of cancelAccList.pList" :key="index">{{index+1}}、{{item}} </p>
				<div class="width-fill margin-t-15 font-color-999 flex-row margin-t-20 flex-top">
					<el-checkbox  v-model="cancelAccList.checked" ></el-checkbox>
					<div class="botText margin-l-10">{{cancelAccList.chooseText}}</div>
				</div>
				<div class="comBtn bg-theme font-color-fff bg-theme text-center radius2">申请注销</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {
	  data() {
	    return {
	      cancelAccList:{
			  checked:true,
			  iconText:"i",
			  titleText:"聚材通将对以下信息进行审核",
			  pList:[
				  "账号当前为有效状态",
				  "账号内无未完成状态订单",
				  "未开通激活店铺",
				  "账户无任何纠纷",
				  "已完成所有金融服务注销或关闭",
				  "已完成所有物流服务注销或关闭",
			  ],
			  chooseText:"申请注销即表示您自愿放弃账号内所有虚拟资产并同意《聚材通账号注销须知》注销成功后，支持聚材通平台账户登录的多项产品/服务将无法使用此账户。"
		  }
	    };
	  },
	  computed: {
	    ...mapGetters(["getThemeName"]),
	    themeClass() {
	      return `theme-${this.getThemeName}`;
	    },
	  },
	};
</script>

<style scoped lang ="scss">
	.color-theme {
	  @include themify($themes) {
	    color: themed("themes_color");
	  }
	}
	.bg-theme {
	  @include themify($themes) {
	    background-color: themed("themes_color");
	  }
	}
	.accontCont{
		height: calc(100% - 10px);
		.titleText{
			padding: 8px 14px;
		}
	}
	.cancelAccCont{
		height: 100%;
		.centerBox{
			width: 500px;
			margin: auto;
			margin-top:50px;
			span{
				display: block;
				height: 32px;
				width: 32px;
				line-height: 32px;
				border: 4px solid #FF9900;
			}
			.el-checkbox{
				margin-top: 3px;
			}
			::v-deep .el-checkbox__inner{
				border-radius: 50px;
			}
			.botText{
				display: inline-block;
				line-height: 26px;
			}
		}
	}
	
	/* 按钮 */
	.comBtn{
		width: 120px;
		height: 40px;
		line-height: 40px;
		cursor: pointer;
		margin: 20px auto;
	}
	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner{
		@include themify($themes) {
		  background-color: themed("themes_color")!important;
		  border-color: themed("themes_color")!important;
		}
	}
	
</style>